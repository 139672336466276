import React from "react";
import { graphql } from "gatsby";
import { Grid, Image, Button } from "semantic-ui-react";
import Helmet from "react-helmet";
import {
  PageLayout,
  HoursOfOperation,
  HeroElement,
  ContactForm,
  Contact,
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";
import LocationsSorted from "../components/LocationsSorted";

export default class extends React.PureComponent {
  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    console.log(data.locations)

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Locations</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout>
          <h1
            style={{ fontSize: "3rem", color: "#fff", margin: "1.5em 0 0 0" }}
          >
            Locations
          </h1>
          <Grid
            stackable
            className="component-section-container locations-page"
            textAlign="center"
          >
            <Grid.Column width={14}>
              <LocationsSorted
                locations={data.locations.map((location) => {
                  const locationNameToOrderLink = {
                    Maywood: "https://order.eggnbird.com/store/13757",
                    "Pico Rivera": "https://order.eggnbird.com/store/13759",
                    Cypress: "https://order.eggnbird.com/store/13755",
                    Upland: "https://order.eggnbird.com/store/13760",
                    "Plano": "https://order.eggnbird.com/store/14961/EGG%20N%20BIRD%20PLANO"
                  };

                  const locationNameToGoogleDirection = {
                    Maywood: "https://goo.gl/maps/73skQLSJfc2hphDq6",
                    "Pico Rivera": "https://goo.gl/maps/ikXaEDNSJjdt78hE7",
                    Cypress: "https://goo.gl/maps/huk5kT3oPvJdsbzs8",
                    Upland: "https://goo.gl/maps/nDcPeGnSeDjqcdfu8",
                    "Plano": "https://maps.app.goo.gl/CreUDCNJL34zPXt76"
                  };

                  let actions = [];

                  if (locationNameToOrderLink[location.name]) {
                    actions = [
                        ...actions,
                        {
                          url: locationNameToOrderLink[location.name],
                          label: "Order Online",
                        }
                    ]
                  }

                  if (locationNameToGoogleDirection[location.name]) {
                    actions = [
                      ...actions,
                      {
                        url: locationNameToGoogleDirection[location.name],
                        label: "Directions",
                      },
                    ]
                  }

                  return {
                    ...location,
                    actions
                  };
                })}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          ctas {
            address {
              link
              text
              internal
            }
          }
          businessName
          phone
          email
          locations {
            name
            address {
              city
              state
              street
              zip
            }
            hours {
              close
              day
              open
              label
            }
            phoneNumber
          }
          address {
            street
            city
            state
            zip
          }
          hours {
            day
            open
            close
          }
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url
          }
          heroImages {
            url
          }
        }
      }
    }
  }
`;
