import React from "react";
import { graphql } from "gatsby";
import { Grid, Button } from "semantic-ui-react";
import Helmet from "react-helmet";
import Markdown from "markdown-to-jsx";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  About,
  ImageCollection,
  Contact,
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Home</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
          hero={
                // <HeroElement
                //   header=""
                //   tagline={""}
                //   images={["https://fisherman.gumlet.io/public/eggandbird/131-23-enb_plant_desktop2.png"]}
                //   showMap={false}
                //   ctas={[]}
                //   ctaInverted={true}
                //   ctaColor={"white"}
                //   containerColor={"primary"}
                //   textColor={"white"}
                //   showMultiple={true}
                //   overlay={true}
                //   fullWidth={true}
                //   parallax={true}
                //   containerAs={"none"}
                //   height={100}
                //   gutter={false}
                // />

                <div className="home-page">
                  <a className="mobile" href="https://order.eggnbird.com/" target="_blank" rel="noopener noreferrer">
                    <HeroElement
                      header={""}
                      tagline={null}
                      images={[
                          "https://fisherman.gumlet.io/public/eggandbird/LTO-Hand-Tossed_Tenders-Mobile-9x16_v2.jpg",
                          "https://fisherman.gumlet.io/public/eggandbird/ENB_Catering_9x16_Mobile_1080x1920.jpg",
                      ]}
                      showMap={false}
                      ctas={[]}
                      ctaInverted={true}
                      ctaColor={"white"}
                      containerColor={"primary"}
                      textColor={"white"}
                      showMultiple={true}
                      overlay={true}
                      fullWidth={true}
                      parallax={true}
                      containerAs={"none"}
                      height={500}
                      gutter={false}
                    />
                  </a>
                  <a className="tablet" href="https://order.eggnbird.com/" target="_blank" rel="noopener noreferrer">
                    <HeroElement
                      header=""
                      tagline={""}
                      images={[
                        "https://fisherman.gumlet.io/public/eggandbird/LTO-Hand-Tossed_Tenders_TABLET_v2.jpg",
                        "https://fisherman.gumlet.io/public/eggandbird/ENB_Catering_4x3_Tablet_2048x1536_gDR6XZd.jpg",
                      ]}
                      showMap={false}
                      ctas={[]}
                      ctaInverted={true}
                      ctaColor={"white"}
                      containerColor={"primary"}
                      textColor={"white"}
                      showMultiple={true}
                      overlay={true}
                      fullWidth={true}
                      parallax={true}
                      containerAs={"none"}
                      height={500}
                      gutter={false}
                    />
                  </a>
                  <a className="desktop" href="https://order.eggnbird.com/" target="_blank" rel="noopener noreferrer">
                    <HeroElement
                        header=""
                        tagline={""}
                        images={[
                          "https://fisherman.gumlet.io/public/eggandbird/LTO-Hand-Tossed_Tenders-Desktop_v2.jpg",
                          "https://fisherman.gumlet.io/public/eggandbird/ENB_Catering_16x9_Desktop_2048x1152.jpg",
                        ]}
                        showMap={false}
                        ctas={[]}
                        ctaInverted={true}
                        ctaColor={"white"}
                        containerColor={"primary"}
                        textColor={"white"}
                        showMultiple={true}
                        overlay={true}
                        fullWidth={true}
                        parallax={true}
                        containerAs={"none"}
                        height={500}
                        gutter={false}
                    />
                  </a>
              </div>
          }
          subfooter={false}
        >
          <Grid
            stackable
            className="component-section-container about-section"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <About
                content={<Markdown>{data.aboutMarkdown}</Markdown>}
                header={`Welcome to ${data.businessName}`}
                centerContent={true}
                headerAs={"h1"}
                withContainer={true}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              {/* <ImageCollection
                as={"carousel"}
                centered={true}
                cutUnevenRows={true}
                disable={"none"}
                header={"Gallery"}
                images={data.galleryImages.map(({ url }) => url)}
                numberOfRowDisplayItems={3}
              /> */}
              <h1>Social Gallery</h1>
              <iframe
                src="https://cdn.lightwidget.com/widgets/b5cd119c9c295cf9977f29a4af3a9dd3.html"
                scrolling="yes"
                allowtransparency="true"
                class="lightwidget-widget"
                style={{
                  width: "100%",
                  border: "0",
                  overflow: "hidden",
                  height: "600px",
                }}
              ></iframe>
            </Grid.Column>
          </Grid>
          <Grid
            stackable
            className="component-section-container rm-padding-top"
          >
            <Grid.Column width={16}>
              <About
                content={
                  <p>
                    <span>
                      We love to hear from our guests - good or bad! Whether you
                      had an issue and need assistance or if you would like to
                      leave a note for the team, contact us at&nbsp;
                    </span>
                    <a
                      style={{ color: "#fff" }}
                      href="mailto:customerservice@eggnbird.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      customerservice@eggnbird.com
                    </a>
                  </p>
                }
                header={`Contact Us`}
                centerContent={true}
                headerAs={"h1"}
                withContainer={true}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          aboutMarkdown
          phone
          email
          address {
            street
            city
            state
            zip
          }
          hours {
            day
            open
            close
          }
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url
          }
          heroImages {
            url
          }
        }
      }
    }
  }
`;
